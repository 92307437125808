<template>  
  <div>
    <div class="background_basic">      
      <div style="height:1px"></div>
      <v-card class="ma-4">   
        <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon class="mr-2">mdi-card-account-details</v-icon>출입승인
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">refresh</v-icon>초기화
          </v-btn>
          <v-btn color="blue" text @click="ExcelExpert('출입승인')" class = "ml-2 font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn :disabled="!ProgramPermissionInfo.okU || this.selected.length == 0" @click="showSelect()" text>
            <v-icon class="mr-1">edit</v-icon>일괄승인처리
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>     
        
        <v-card flat class="d-flex ml-3 mr-3">                
          <v-card flat class="ml-3 mb-1">             
            <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
          </v-card>                                         
          <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
            <v-select v-model="cEnterType" class="mb-n5" label="유형" :items="zEnterType" item-value="sId" item-text="sNm"></v-select>   
          </v-card>           
          <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
            <v-select v-model="cResult" class="mb-n5" label="상태" :items="zReqStateTypeAll" item-value="sId" item-text="sNm"></v-select>   
          </v-card>           
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-3 mb-n5"
            label="검색어"
            placeholder="신청자명 or 승인자명 or 소속명 or 관리번호 입력"
            persistent-placeholder
            style="max-width:512px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
          
          <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
            <v-icon>search</v-icon>
          </v-btn>
        </v-card>
      </v-card>    

      <v-data-table 
        class="ma-4 elevation-3"
        :items-per-page="-1" 
        fixed-header 
        height="calc(100vh - 227px)" 
        :headers="headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick"
        show-select
        v-model="selected"
        item-key="cIdx"
        >
        <template v-slot:item.action="{ item }">
          <v-btn v-if="item.cMapNm != ''" small :disabled="!ProgramPermissionInfo.okU" @click="editItem(item)" dark>
            <v-icon small class="mr-1">edit</v-icon>승인
          </v-btn>
          <v-btn label small v-else color="red lighten-2" dark @click="setMap(item)"><v-icon small class="me-2">mdi-map</v-icon>출입</v-btn>
          <v-btn label small v-if ="item.cResult == 'REQ'" color="ml-2 grey darken-2" dark @click="MemberSelectClik(item)"><v-icon small class="me-2">mdi-account</v-icon>+인원</v-btn>          
        </template>

        <template v-slot:item.cResult="{ item }">
          <v-chip label small v-if ="item.cResult == 'REQ'" color="green lighten-2" dark>신청중</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'OK'" color="blue lighten-3" dark>승인</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'NG'" color="red lighten-3" dark>반려</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'HOLD'" color="black" dark>보류</v-chip>
          <v-chip label small v-else color="grey lighten-3" >{{item.cResult}}</v-chip>
        </template>
        <!-- <template v-slot:item.cMapNm="{ item }">
          <v-btn label small v-if ="item.cMapNm == ''" color="red lighten-2" dark @click="setMap(item)"><v-icon small class="me-2">mdi-map</v-icon>출입공정</v-btn>
          <div label small v-else color="grey lighten-3" >{{item.cMapNm}}</div>
        </template> -->
        <template v-slot:item.cType="{ item }">
          <v-chip label small v-if ="item.cType == 'G'"  color="blue lighten-2" dark>일반</v-chip>

          <v-tooltip v-else-if ="item.cType == 'J'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip label small v-bind="attrs" v-on="on" color="green lighten-3" dark>작업</v-chip>
            </template>
            
            <JobInfoWindow :item="item"></JobInfoWindow>
          </v-tooltip>

          
          <v-chip label small v-else color="grey lighten-3" >{{item.cType}}</v-chip>
        </template>

        <template v-slot:item.cOKUserNm="{ item }">
          <div v-if ="item.cOKUserNm != 'NONE'">{{item.cOKUserNm}}</div>          
        </template>
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>    
    <SetEnterResult ref="SetEnterResultRef" @onSetEnterResult="onSetEnterResult" />        
    <GetSelMap2 ref="GetSelMapRef" @onGetMap="onGetMap" />      
    <MemberSelect ref="MemberSelectRef" @onSelect="onSelectMember" />
  </div>
  </div>
</template>

<script>
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import IronDate from '../components/iron28Calendar/src/IronDate.vue'
import SetEnterResult from '../components/SetEnterResult.vue'
import JobInfoWindow from "@/components/JobInfoWindow.vue";
import GetSelMap2 from "@/components/GetSelMap2.vue";
import MemberSelect from "@/components/MemberSelect.vue";

export default {
  components: {    
    IronDate,        
    SetEnterResult,
    JobInfoWindow,
    GetSelMap2,
    MemberSelect,
  },
  data: () => ({    
    isUpdating: false,    
    selected: [],    
    sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Util.sTomorrowSel()),
    sStartDate : Util.sFormatDate(Date()),
    sEndDate :Util.sFormatDate(Util.sTomorrowSel()),
    cNowSearchKeyWord : "",    
    cNowCheck: "A",
    cResult : "ALL",    
    cEnterType : "ALL",    
    loading : false,
    isEditMode : false,
    iDelIndex: 0,
    dialog: false,    
    menu : false,  
    zSelLocation : [],
    headers: [      
      { class: "font-weight-bold subtitle-2", text: "관리번호", value: "cIdx", align: "center", width:"50" },      
      { class: "font-weight-bold subtitle-2", text: "출입일", value: "cReqDateTime", align: "center", width:"120"},
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cType", align: "center", width:"50"},
      { class: "font-weight-bold subtitle-2", text: "출입목적", value: "cReqMessage", align: "center", width:"400"},  
      { class: "font-weight-bold subtitle-2", text: "소속", value: "cCorpNm", align: "center", width:"100"},  
      { class: "font-weight-bold subtitle-2", text: "작업자", value: "cUserNms", align: "center", width:"250"},
      { class: "font-weight-bold subtitle-2", text: "구역", value: "cMapNm", align: "center", width:"250"},          
      { class: "font-weight-bold subtitle-2", text: "상태", value: "cResult", align: "center", width:"50"},
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false, width:"200"},    
      { class: "font-weight-bold subtitle-2", text: "의견", value: "cMessage", align: "center", width:"150"},    
      { class: "font-weight-bold subtitle-2", text: "신청자", value: "cReqUserNm", align: "center", width:"120"},  
      { class: "font-weight-bold subtitle-2", text: "승인자", value: "cOKUserNm", align: "center", width:"120"}, 
      { class: "font-weight-bold subtitle-2", text: "요청일시", value: "cCreateDateTime", align: "center", width:"120"},  
      { class: "font-weight-bold subtitle-2", text: "승인일시", value: "cOKDateTime", align: "center", width:"120"},  

    ],

    Exheaders: [      
      { class: "font-weight-bold subtitle-2", text: "관리번호", value: "cIdx", align: "center", width:"50" },      
      { class: "font-weight-bold subtitle-2", text: "출입일", value: "cReqDateTime", align: "center", width:"120"},
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cTypeNm", align: "center", width:"50"},
      { class: "font-weight-bold subtitle-2", text: "출입목적", value: "cReqMessage", align: "center", width:"400"},  
      { class: "font-weight-bold subtitle-2", text: "소속", value: "cCorpNm", align: "center", width:"100"},  
      { class: "font-weight-bold subtitle-2", text: "작업자", value: "cUserNms", align: "center", width:"250"},
      { class: "font-weight-bold subtitle-2", text: "작업자수", value: "cUserCount", align: "center", width:"250"},
      { class: "font-weight-bold subtitle-2", text: "구역", value: "cMapNm", align: "center", width:"250"},          
      { class: "font-weight-bold subtitle-2", text: "상태", value: "cResult", align: "center", width:"50"},
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false, width:"200"},    
      { class: "font-weight-bold subtitle-2", text: "의견", value: "cMessage", align: "center", width:"150"},    
      { class: "font-weight-bold subtitle-2", text: "신청자", value: "cReqUserNm", align: "center", width:"120"},  
      { class: "font-weight-bold subtitle-2", text: "승인자", value: "cOKUserNm", align: "center", width:"130"}, 
      { class: "font-weight-bold subtitle-2", text: "요청일시", value: "cCreateDateTime", align: "center", width:"120"},  
      { class: "font-weight-bold subtitle-2", text: "승인일시", value: "cOKDateTime", align: "center", width:"120"},  
    ],
    Records: [],
    editedIndex: -1,
    sNowIdx : "", 
    editedItem: { 
      cIdx: "",
      cReqDateTime : Util.sFormatDate(Date()),
      cUserCount : "0",
      zUserId : [],
      zMap : [],
      cType: "",
      cReqMessage: "",
      cResult: "",
      cMessage: "",
      cReqUserNm: "",
      cOKUserNm: "",
      cUserNms: "",
      cMapNm: "",
    },
    deletedItem: {
      cIdx: "",
      cReqDateTime : Util.sFormatDate(Date()),
      cUserCount : "0",
      zUserId : [],
      zMap : [],
      cType: "",
      cReqMessage: "",
      cResult: "",
      cMessage: "",
      cReqUserNm: "",
      cOKUserNm: "",
      cUserNms: "",
      cMapNm: "",
    },
    defaultItem: {
      cIdx: "",
      cReqDateTime : Util.sFormatDate(Date()),
      cUserCount : "0",
      zUserId : [],
      zMap : [],
      cType: "",
      cReqMessage: "",
      cResult: "",
      cMessage: "",
      cReqUserNm: "",
      cOKUserNm: "",
      cUserNms: "",
      cMapNm: "",
    }
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zReqStateTypeAll", "zEnterType",]),
    formTitle() {
      return this.editedIndex === -1 ? "신규 등록" : "항목 수정";
    },
  },

  mounted() {
    this.initialize();
  },

  
  methods: {        
    MemberSelectClik(item){      
      this.editedItem = item;
      // this.$refs.MemberSelectRef.Show(item.cCorpId);
      this.$refs.MemberSelectRef.Show(item.cCorpId, item.cCorpNm);
    },
    onSelectMember(data){      
      if (data.length == 0) {return}

      var zUser = [];
      var zTag = [];

      for (let i = 0; i < data.length; i++) {
        zUser.push(data[i].cUserId);
        zTag.push(data[i].cTagId);
      }

      var posData = {
        cIdx: this.editedItem.cIdx,        
        zUser : zUser,
        zTag : zTag,        
      }
      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "AddEnterReqLogMember", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            this.editedItem.cUserNms = res.data.Result[0].cUserNms;        

            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });          
    },

    initialize() {
      this.cNowSearchKeyWord = "";
      this.cResult = "ALL";      
      this.cEnterType = "ALL";
      
      this.sNowSelectDate = Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Util.sTomorrowSel());   
      this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      this.sStartDate = Util.sFormatDate(Date());
      this.sEndDate = Util.sFormatDate(Util.sTomorrowSel());
      
      
      this.Search();    
    },

    onSetEnterResult(posData, data){      
      for (let i = 0; i < posData.zIdx.length; i++ ){             
        for (let j = 0; j < this.Records.length; j++){      
          if (posData.zIdx[i] == this.Records[j].cIdx) {            
            this.Records[j].cResult = posData.cResult;
            this.Records[j].cMessage = posData.cMessage;
            this.Records[j].cOKDateTime = data.Result[0].cUpdateDateTime;    
            break;
          }
        }
      }

      this.selected = [];
    },
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },

    
    handleDoubleClick(value, items) { 
      if (!this.ProgramPermissionInfo.okU) return;

      console.log(items.item);

      if (items.item.cMapNm == "") {
        this.setMap(items.item);
      } else {
        this.editItem(items.item);  
      }
    },


    setMap(item){      
      this.editedIndex = this.Records.indexOf(item);       
      this.editedItem = Object.assign({}, item);       
      this.sNowIdx = item.cIdx;
      this.$refs.GetSelMapRef.show([]);       
    },


    onGetMap(zSelect, cMapNm) {
      if (zSelect.length == 0) return;

      this.editedItem.zMap = zSelect;
      this.editedItem.cMapNm = cMapNm;


      var posData = {
        cIdx: this.sNowIdx,                             
        zMapId : this.editedItem.zMap,        
      }

      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "ReqEnterReqLogMap", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") { 
            Object.assign(this.Records[this.editedIndex], this.editedItem);
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });

    },
    
    Search() {
      this.Records = [];
      this.selected = [];
      var posData = {
        cStartDatetime: this.sStartDate + " 00:00:00",  
        cEndDatetime  : this.sEndDate + " 23:59:59",
        cResult: this.cResult,
        cEnterType: this.cEnterType,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        zMapId : this.UserInfo.zMapIdAll,
      };
      axios
        .post(BasicInfo.UIL_API + "GetEnterReqLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result1;         

            for (let i = 0; i < res.data.Result1.length; i++){
              this.Records[i].zUserId = [];
              this.Records[i].zUserId = res.data.Result1[i].cUserIds.split(",");
              this.Records[i].cUserCount = this.Records[i].zUserId.length;
              this.Records[i].zMap = [];

              for (let j = 0; j < res.data.Result2.length; j++){
                if (res.data.Result2[j].cIdx == this.Records[i].cIdx) {
                  this.Records[i].cMapNm = res.data.Result2[j].cMapNm;
                  this.Records[i].zMap = res.data.Result2[j].cMapId.split(",");
                  break;
                }
              }
            }

               
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },
    

    
    close() {      
      this.dialog = false;
      this.isEditMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    
    editItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);       

      this.$refs.SetEnterResultRef.Show(this.editedItem.cIdx);

      
    },

    showSelect() {
      var z = 0;
      while (z < this.selected.length) {     
        console.log("Check", this.selected[z].cIdx, this.selected[z].cMapNm);
        if (this.selected[z].cMapNm == '') {
          console.log("Delete", this.selected[z].cIdx, this.selected[z].cMapNm);
          this.selected.splice(z, 1);
        } else {
          z = z + 1;
        }          
      }

      if (this.selected.length > 0) {
        var a = [];

        for(let i = 0; i < this.selected.length; i++){
            a.push(this.selected[i].cIdx);
        }

        if (a.length > 0) {
          this.$refs.SetEnterResultRef.ShowSelect(a);        
        }        
      } else {
        EventBus.$emit("onShowMsg",false, "선택된 내용이 없습니다.");
      }
    },

    
    
    ExcelExpert(name){
      for (let i = 0; i < this.Records.length; i++){
        if (this.Records[i].cType == "G") {
          this.Records[i].cTypeNm = "일반";
        } else if (this.Records[i].cType == "J") {
          this.Records[i].cTypeNm = "작업";        
        }  
      }      
      var r = Util.GetExcel(this.Records, this.Exheaders);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },
  }
};
</script>

