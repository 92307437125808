<template>    
  <v-dialog max-width="500px" v-model="dialog">
    <v-card >              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          출입신청 승인 등록
        </v-toolbar-title>          
        <v-divider class="mx-4" inset vertical></v-divider>                    
      </v-toolbar>
      <v-divider></v-divider>              
      <v-card-text>
        <v-select
          :items="zReqStateType"
          label="유형"
          item-text="sNm"
          item-value="sId"          
          v-model="cType"
        ></v-select>


        <v-textarea              
          v-if="cType != 'OK'"
          v-model="cCheckData"
          label="의견 입력"            
          hint="의견을 입력하세요."
          outlined
          no-resize
        ></v-textarea>
        
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="black darken-1" text @click="closePage()"><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>
        <v-btn color="blue darken-1" text @click="SetData()"><v-icon class="mr-2">check_circle</v-icon>저장</v-btn>        
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';
import { mapState } from "vuex";

export default {
  components: {    
  },
  data() {
    return {
      dialog: false,
      bOneProcess : false,
      sDate : "",      
      model : null,            
      cType : "OK",     
      cEnterIdx : "",
      zEnterIdx : [],
      cCheckData : "",
      
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },

  computed: {
    ...mapState(["zReqStateType"]),
  },

  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage(){                   
      this.dialog = false;
    },

    SetData() {      
      if (this.bOneProcess) {
        this.zEnterIdx = [this.cEnterIdx];
      }
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        zIdx : this.zEnterIdx,          
        cResult : this.cType,        
        cMessage : this.cCheckData,
      };

      if (this.cType == 'OK') {
        posData.cMessage = "";
      }
      
      axios
        .post(BasicInfo.UIL_API + "SetEnterResult", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            this.$emit("onSetEnterResult", posData, res.data);
            this.dialog = false;  
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
        });
    },

    Show: function(cEnterIdx) {
      this.bOneProcess = true;
      this.cEnterIdx = cEnterIdx;
      this.cType = "OK";
      this.cCheckData = "";
      this.dialog = true;      
    },

    ShowSelect: function(zEnterIdx) {      
      this.bOneProcess = false;
      this.zEnterIdx = zEnterIdx;
      this.cType = "OK";
      this.cCheckData = "";
      this.dialog = true;      
    },
  }
};
</script>
